import { getStoreAccessors } from 'typesafe-vuex';
import { RootState } from '@white-label-types/stores';
type ProductsType = GenerateProduct | ExtrasCancellationProtectionProduct;
export type State = {
  showBasketSlideOver: boolean;
  storeBasketDot: boolean;
  continueClicked: boolean;
  latestBasketItem: null | ProductsType;
  showBasketNotification: boolean;
};
import type {
  GenerateProduct,
  ExtrasCancellationProtectionProduct,
} from '@white-label-types/parking-booking';
const state = (): State => ({
  showBasketSlideOver: false,
  storeBasketDot: true,
  continueClicked: false,
  latestBasketItem: null,
  showBasketNotification: false
});

const getters = {
  showBasketSlideOver: (state: State) => state.showBasketSlideOver,
  showBasketNotification: (state: State) => state.showBasketNotification,
  showlatestBasketItem: (state: State) => state.latestBasketItem,
  storeBasketDot: (state: State) => state.storeBasketDot,
  continueClicked: (state: State) => state.continueClicked,
};

const mutations = {
  storeShowBasketSlideOver(state: State, payload: boolean) {
    state.showBasketSlideOver = payload;
  },
  storeBasketDot(state: State, payload: boolean) {
    state.storeBasketDot = payload;
  },
  showLatestBasketItem (state: State, payload: ProductsType, show: boolean = true) {
    state.latestBasketItem = payload
    if( show ) {
      state.showBasketNotification = true
    }
  },
  showBasketNotification(state: State, payload: boolean ) {
    state.showBasketNotification = payload;
  },
  storeContinueClicked(state: State, payload: boolean) {
    state.continueClicked = payload;
  },
};

export default {
    namespace: true,
    state,
    getters,
    mutations
}
const { commit, read } = getStoreAccessors<State, RootState>('multiBasket');

export const readShowBasketSlideOver = read(getters.showBasketSlideOver);
export const readStoreBasketDot = read(getters.storeBasketDot);
export const readShowBasketNotification = read(getters.showBasketNotification);
export const readLatestBasketItem = read(getters.showlatestBasketItem);
export const readContinueClicked = read(getters.continueClicked);
export const commitShowBasketSlideOver = commit(mutations.storeShowBasketSlideOver);
export const commitStoreBasketDot = commit(mutations.storeBasketDot);
export const commitShowBasketNotification = commit(mutations.showBasketNotification);
export const commitShowLatestBasketItem = commit(mutations.showLatestBasketItem);
export const commitContinueClicked = commit(mutations.storeContinueClicked);
