

// Packages
import { defineComponent } from 'vue';
import { MetaInfo } from 'vue-meta';
import { mapActions } from 'pinia';

// Helpers
import { DOMAINS_NAMES, getDomainUrl } from '@white-label-helper/switch-app'

// Stores
import { usePreloaderControllerStore } from '@white-label-store/preloader-controller';

export default defineComponent({
  name: 'Error',

  components: {
    ErrorPage: () => import('@white-label-frontend/error'),
  },

  head(): MetaInfo {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      script: [
        {
          json: this.$partnerConfig as { [key: string]: any },
          type: 'application/json',
          vmid: 'partner-channel-config',
        },
      ]
    };
  },

  // Remove preloader for avoid wrong preloader on this page
  created() {
    this.commitUpdatePreloader('');
  },

  mounted() {
    // TODO Need to delete when dev will moved to hosting, which supports Nuxt middleware
    this.browserSupportCheck();
  },

  methods: {
    ...mapActions(usePreloaderControllerStore, {
      commitUpdatePreloader: 'updatePreloader',
    }),
    goBack() {
      const domain = getDomainUrl(DOMAINS_NAMES.ECOMMERCE);
      if (domain) {
        window.location.replace(domain);
      }
    },
  },
});
