
  import { defineComponent } from 'vue';
  import { MetaInfo } from 'vue-meta';

  export default defineComponent({
    name: 'EmptyLayout',

    head(): MetaInfo {
      return {
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        script: [
          {
            json: this.$partnerConfig as { [key: string]: any },
            type: 'application/json',
            vmid: 'partner-channel-config',
          },
        ]
      }
    },
  });
  