import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61f67df0 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _1b22c8f0 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _0a94030f = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _72763c9c = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _316f92a6 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _6ec1327a = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _318d3edc = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _f5c52e68 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _4f58cb4f = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _76ee642b = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _4d1da085 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _4a3dd0d0 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _6638724d = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _1db69435 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _50cbbc5e = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _43b60eac = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _5e2ce2b6 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _2f314ca8 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _1a23f5b6 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _92c838f2 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _61f67df0,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _1b22c8f0,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _0a94030f,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _72763c9c,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _316f92a6,
    name: "receipt"
  }, {
    path: "/search",
    component: _6ec1327a,
    name: "search"
  }, {
    path: "/search-booking",
    component: _318d3edc,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _f5c52e68,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _4f58cb4f,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _76ee642b,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _4d1da085,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _4a3dd0d0,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _6638724d,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _1db69435,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _50cbbc5e,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _43b60eac,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _5e2ce2b6,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _2f314ca8,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _1a23f5b6,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _92c838f2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
