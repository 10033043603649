onResize();
window.addEventListener('resize', onResize);

/**
 *
 * @returns {void}
 */
function onResize(): void {
  if (window.innerWidth > Infinity) return;

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
