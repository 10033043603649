import type { Receipt } from '@white-label-types/parking-checkout';

export function parseReceiptState(baseState: Receipt) {
  return {
    ...baseState,
    items: baseState.items.map((item) => {
      return {
        ...item,
        parkingName: item.inventory_item.product_name,
        locationInfo: item.poi,
        orderId: item.order_reference,
      };
    }),
  };
}
