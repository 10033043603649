import createPersistedState from 'vuex-persistedstate';
import type { Plugin } from '@nuxt/types';

const persistedStatePlugin: Plugin = (context) => {
  createPersistedState({
    paths: ['cart'],
  })(context.store);
}

export default persistedStatePlugin
