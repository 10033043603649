// Packages
import { getStoreAccessors } from 'typesafe-vuex';

// Types
import type {
  RootState,
  PaymentFormState,
  PaymentFormGetters,
  PaymentFormMutations,
} from '@white-label-types/stores';

const paymentFormState = (): PaymentFormState => ({
  formData: null,
  timestamp: null,
});

const paymentFormGetters: PaymentFormGetters = {
  formData: (state) => state.formData,
  timestamp: (state) => state.timestamp,
};

const mutations: PaymentFormMutations = {
  storeFormData(state, payload) {
    state.formData = { ...payload.formData };
    if (payload.timestamp) state.timestamp = payload.timestamp;
  },

  clearFormData(state) {
    state.formData = null;
    state.timestamp = null;
  },
};

const { read, commit } = getStoreAccessors<PaymentFormState, RootState>('paymentForm');

// Mutations
export const commitStoreFormData = commit(mutations.storeFormData);
export const commitClearFormData = commit(mutations.clearFormData);
export const readPaymentFormData = read(paymentFormGetters.formData);

export default {
  namespaced: true,
  state: paymentFormState,
  getters: paymentFormGetters,
  mutations,
};
