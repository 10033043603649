/* eslint-disable */
// @ts-nocheck
// Code provided by Webloyalty - please don't change any logic in this function
// Prepares Webloyalty by adding necessary properties to the window object
const registerWebloyality = () => {
  if (process.client) {
    window.prodID = {
      "a": [],
      "c": 0,
      "u": "",
      "r": false
    };
    window._lppr = function(v) {
      for (let i = 0; i < prodID.a.length; i++) {
          if (prodID.a[i].h === v) {
              return i;
          }
      }
    };
  }
}

export default registerWebloyality;
