
import { defineComponent } from 'vue';
import { formatPrice } from '@white-label-helper/helper-payment';

// Mixin
import multiBasket from '@white-label-helper/mixin-multi-basket';

import type { CartItem, Receipt } from '@white-label-types/parking-checkout';
type SummaryElementItems = CartItem[] | Receipt['items'];
type SummaryElementItem = SummaryElementItems extends (infer U)[] ? U : never;

import { ProductSummaryCard, CavuButton } from 'ui-shared-components';
import {
  readShowBasketNotification,
  readShowBasketSlideOver,
} from '@white-label-store/multi-basket';
import { readTotals, readItems } from '@white-label-store/cart';
import { ROUTE_NAMES } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'BasketMobile',
  components: {
    CavuButton,
    IconArrowDownSmall: () => import('@white-label-icon/icon-arrow-down-small'),
    IconBasket: () => import('@white-label-icon/icon-basket'),
    IconClose: () => import('@white-label-icon/icon-close'),
    IconCart: () => import('@white-label-icon/icon-cart'),
    ProductSummaryCard,
  },
  mixins: [multiBasket],
  data() {
    return {
      isVisible: false,
      activeClass: '',
      isExpanded: false,
    };
  },
  computed: {
    isOpen(): boolean {
      return readShowBasketSlideOver(this.$store);
    },
    total(): string | Intl.NumberFormat {
      const total = Number(readTotals(this.$store)?.total);
      return formatPrice(total);
    },
    cartItems(): ReturnType<typeof readItems> {
      return readItems(this.$store);
    },
    showBasket(): boolean {
      if (!this['isMultiBasket']) return false;
      const currentRouteName = this.$route.name;
      if (
        currentRouteName !== ROUTE_NAMES.home &&
        currentRouteName !== ROUTE_NAMES.search
      )
        return false;
      if (currentRouteName === ROUTE_NAMES.search) return true;
      return this.hasBasketItems;
    },
    hasBasketItems(): boolean {
      return this.cartItems.length > 0;
    },
    basketValue(): string {
      const totals = this.cartItems.reduce((total, item) => {
        const itemTotals =
          'original_amounts' in item
            ? item.original_amounts.totals
            : (item as SummaryElementItem).totals;
        return total + Number(itemTotals.total);
      }, 0);
      return this.hasBasketItems
        ? `${formatPrice(totals)}`
        : `${formatPrice(0)}`;
    },
    showBasketNotification(): ReturnType<typeof readShowBasketNotification> {
      return readShowBasketNotification(this.$store);
    },
  },
  watch: {
    isOpen(val) {
      this.toggleActiveState(val);
    },
  },
  methods: {
    toggleActiveState(isActive: boolean): void {
      if (isActive) {
        this.isVisible = true;
        setTimeout(() => {
          this.activeClass = 'basket-desktop__body--active';
        }, 200);
      } else {
        this.activeClass = '';
        setTimeout(() => {
          this.isVisible = false;
        }, 500);
      }
    },
    onContinueHandler() {
      this.$emit('continue');
    },
    onClose() {
      if (this.isOpen) {
        this.$store.commit('multiBasket/storeShowBasketSlideOver', false);
      }
    },
  },
});
