import Vue from 'vue';
import { SCREENS } from '@white-label-configuration/constants';

import type { DirectiveBinding } from 'vue';

const addPaddingToBody = (el: HTMLElement) => {
  document.body.style.paddingBottom = `${el.offsetHeight - 10}px`;
};

const removePaddingFromBody = () => {
  document.body.style.paddingBottom = '0';
};

let resizeHandler: () => void;

// directive adds a compensating bottom padding to the body with the value of an element height
// that is positioned fixed and can overlap some content at the bottom of the page
Vue.directive('body-padding', {
  inserted: (el: HTMLElement) => {
    resizeHandler = () => {
      if (window.innerWidth < SCREENS.LG) {
        addPaddingToBody(el);
      } else {
        removePaddingFromBody();
      }
    };

    // run handler to add initial padding
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
  },

  componentUpdated: (el: HTMLElement) => {
    if (window.innerWidth < SCREENS.LG) {
      addPaddingToBody(el);
    }
  },

  unbind: () => {
    removePaddingFromBody();
    window.removeEventListener('resize', resizeHandler);
  },
});

Vue.directive('scroll-listener', {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind(el: HTMLElement, binding: DirectiveBinding<any>) {
    if (typeof binding.value !== 'function') return;

    el.addEventListener('scroll', binding.value);
  },
  unbind(el, binding) {
    if (typeof binding.value !== 'function') return;

    el.removeEventListener('scroll', binding.value);
  },
});
